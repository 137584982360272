<template>
    <section class="layout-affiliated-warning">
        <div class="layout-affiliated-warning__wrapper">
            <div class="layout-affiliated-warning__subwrapper">
                <img :src="$assets.gray.errorCircle" alt="icono de aviso" />
                <p class="description">
                    {{ title }}
                    <NuxtLink :to="linkUrl || ''" class="link"> {{ linkText }}</NuxtLink>
                </p>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true,
        },
        linkText: String,
        linkUrl: String,
    },
    data() {
        const scrollToComponent = useScrollToComponent()
        return {
            scrollToComponent,
        }
    },
})
</script>

<style lang="postcss" scoped>
.layout-affiliated-warning {
    @apply py-2;

    &__wrapper {
        @apply flex h-full items-center justify-center;
    }

    &__subwrapper {
        @apply container flex h-full items-center justify-center gap-x-1 overflow-hidden rounded-md;
        & .description,
        .link {
            @apply text-center text-[13px] font-normal leading-[14px] tracking-wide text-black;
        }

        & .link {
            @apply underline;
        }
    }
}
</style>

<!-- <template>
    <section class="layout-affiliated-warning">
        <div class="layout-affiliated-warning__wrapper">
            <div class="layout-affiliated-warning__subwrapper"> 
                <p class="description">
                    {{ title }}
                    <button
                        v-if="hash"
                        type="button"
                        @click="() => scrollToComponent(hash || '')"
                        class="link"
                    >
                        Leer más
                    </button>
                </p>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true,
        },
        hash: String,
    },
    data() {
        const scrollToComponent = useScrollToComponent()
        return {
            scrollToComponent,
        }
    },
})
</script>

<style lang="postcss" scoped>
.layout-affiliated-warning {
    @apply h-14 bg-[#DDDDDD] xs:h-9 md:h-6;

    &__wrapper {
        @apply flex h-full items-center justify-center;
    }

    &__subwrapper {
        @apply container flex h-full items-center justify-center gap-x-1 overflow-hidden rounded-md;
        & .description,
        .link {
            @apply text-center text-[13px] font-normal leading-[14px] tracking-wide text-black;
        }

        & .link {
            @apply underline;
        }
    }
}
</style> -->
